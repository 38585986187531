/* import __COLOCATED_TEMPLATE__ from './filterable-list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  ALL_CONTENT_COLUMN_DATA,
  type ColumnData,
  DEFAULT_SELECTABLE_COLUMNS,
  KNOWLEDGE_HUB_ALL_CONTENT_DEFAULT_COLUMNS,
  IMPORT_SOURCE_STATUSES,
  KNOWLEDGE_HUB_REQUIRED_COLUMNS,
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  FOLDER_MULTILINGUAL_DEFAULT_COLUMNS,
  type SortDirection,
  type TagOperator,
} from 'embercom/lib/knowledge-hub/constants';

import { type TableColumn } from 'embercom/components/knowledge-hub/filterable-list/list';
import type IntlService from 'ember-intl/services/intl';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';
import { type Predicate } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import {
  BULK_ACTION_PROGRESSION_ID,
  type BulkActionProgressionEvent,
  BulkActionProgressionValues,
} from 'embercom/services/knowledge-hub-service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { type EntityType } from 'embercom/models/data/entity-types';
import type Folder from 'embercom/models/content-service/folder';
import { action } from '@ember/object';
import {
  type SyncIssuesFilterOptions,
  type FolderFilterOption,
} from 'embercom/lib/content-service/search-api';
import { isEmpty } from '@ember/utils';
import { type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  IMPORT_JOB_STATUS_EVENT_ID,
  IMPORT_JOB_DELETE_STATUS_ID,
  type UpdateImportSourceStatusEvent,
  type ImportServiceImportSourceDeletedEvent,
} from 'embercom/services/knowledge-hub-service';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { SyncBehaviorType } from 'embercom/models/import-service/import-source';
import type Router from '@ember/routing/router-service';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    title: string;
    folder?: Folder;
    setSearchTerm: (searchTerm?: string) => void;
    searchTerm?: string;
    listApi: KnowledgeHubApi;
    locale: string | null;
    setLocale: (locale: string | null) => void;
    predicates?: Array<Predicate>;
    setPredicates: (predicates: Array<Predicate>) => void;
    status: string | null;
    setStatus: (status: string | null) => void;
    chatbotState?: string;
    setChatbotState: (chatbotState?: string) => void;
    copilotState?: string;
    setCopilotState: (copilotState?: string) => void;
    createdBy?: string;
    setCreatedBy: (createdBy?: string) => void;
    selectedTypes?: Array<EntityType>;
    setSelectedTypes: (selectedTypes?: Array<EntityType>) => void;
    urlSourceId?: string;
    setUrlSourceId: (urlSourceId?: string) => void;
    selectedHelpCenterIds?: Array<string>;
    setSelectedHelpCenterIds: (selectedHelpCenterIds?: Array<string>) => void;
    lastUpdatedBy?: string;
    setLastUpdatedBy: (lastUpdatedBy?: string) => void;
    sortDirection?: SortDirection;
    sortBy?: string;
    setSortBy: (sortBy?: string) => void;
    setSortDirection: (sortDirection?: string) => void;
    displayFolderFilter?: boolean;
    selectedFolderFilterOption?: FolderFilterOption;
    setFolderFilterOption?: (folderFilterOption?: FolderFilterOption) => void;
    selectedSyncIssueOption?: SyncIssuesFilterOptions;
    setSyncIssueFilterOption?: (syncIssueOption?: SyncIssuesFilterOptions) => void;
    selectableColumns?: ColumnData[];
    resetFilters: () => void;
    activeFilter?: string;
    setActiveFilter: (filter?: string) => void;
    filtersApplied?: string[];
    setFiltersApplied: (filters: string[]) => void;
    tagIds?: string[];
    setTagIds: (tagIds?: string[]) => void;
    tagOperator: TagOperator;
    setTagOperator: (tagOperator: TagOperator) => void;
  };
}

export const SHOW_ALL_TRANSLATIONS = 'knowledge_hub_all_content_show_all_translations';

export enum SyncSteps {
  Connect = 'connect',
  ConfluenceSync = 'confluence-sync',
  GuruSync = 'guru-sync',
  NotionSync = 'notion-sync',
}

export const KNOWLEDGE_HUB_ALL_CONTENT_COLUMNS = 'knowledge_hub_all_content_columns';

export default class FilterableList extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare permissionsService: $TSFixMe;
  @tracked showAllToggled: boolean;
  @tracked step?: SyncSteps;
  @tracked showCreateFolderModal = false;
  @tracked showNewContentModal = false;
  @tracked showFileUploadModal = false;

  @tracked selectedFragments: Set<ContentFragment> = new Set();
  @tracked unselectedFragments: Set<ContentFragment> = new Set();
  @tracked isBulkSelection = false;

  @trackedInLocalStorage({
    keyName: KNOWLEDGE_HUB_ALL_CONTENT_COLUMNS,
  })
  columnsLocalStorage: string | undefined;
  @tracked columnsWhenFiltering = this.columns;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let showAllTranslations = localStorage.getItem(SHOW_ALL_TRANSLATIONS);
    this.showAllToggled = showAllTranslations === 'true';
    this.knowledgeHubService.subscribeToImportServiceUpdates();
    this.setImportServiceUpdateActions();
    this.setBulkActionProgressActions();
  }

  willDestroy(): void {
    super.willDestroy();
    this.destroyNexusEvents();
  }

  setImportServiceUpdateActions() {
    this.realTimeEventService.on(
      IMPORT_JOB_STATUS_EVENT_ID,
      this,
      'updateImportSourceStatusAndNotify',
    );
    this.realTimeEventService.on(
      IMPORT_JOB_DELETE_STATUS_ID,
      this,
      'resetFiltersAndNotifyDeletion',
    );
  }

  setBulkActionProgressActions() {
    this.realTimeEventService.on(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  private destroyNexusEvents() {
    this.realTimeEventService.off(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  async updateImportSourceStatusAndNotify(event: UpdateImportSourceStatusEvent) {
    let source = this.store.peekRecord('import-service/import-source', event.import_source_id);
    if (!source) {
      return;
    }

    source.status = event.status;

    if (event.status === IMPORT_SOURCE_STATUSES.success) {
      if (event.sync_behavior === SyncBehaviorType.Sync) {
        this.knowledgeHubService.notifySourceStateChangeConfirmation(
          this.intl.t('knowledge-hub.filterable-list.sources.nexus-event-sync-content-success'),
          `${event.import_source_id}`,
        );
      }

      if (event.sync_behavior === SyncBehaviorType.Import) {
        this.knowledgeHubService.notifySourceStateChangeConfirmation(
          this.intl.t('knowledge-hub.filterable-list.sources.import-complete', {
            sourceName: source.sourceName,
          }),
          `${event.import_source_id}`,
        );
      }

      this.resetFilters();
      this.hardReloadImportSources();
    }
  }

  async resetFiltersAndNotifyDeletion(event: ImportServiceImportSourceDeletedEvent) {
    if (event.is_deleted) {
      this.knowledgeHubService.notifySourceStateChangeConfirmation(
        this.intl.t('knowledge-hub.filterable-list.sources.nexus-event-delete-success'),
        `${event.import_source_id}`,
      );
      this.resetFilters();
      this.store.unloadAll('content-service/folder'); // otherwise deleted synced folders stick around
      let folderIds = (await this.knowledgeHubService.fetchFoldersOnce(true)).map(
        (folder) => folder.id,
      );

      if (this.args.folder && !folderIds.includes(this.args.folder?.id)) {
        // if the active folder isn't in the new list of folders it was deleted
        // so navigate to root folder
        this.router.transitionTo('apps.app.knowledge-hub.all-content');
      }
    } else {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.nexus-event-delete-error'),
      );
    }
  }

  async handleBulkActionProgression(event: BulkActionProgressionEvent) {
    let currentAdmin = this.appService.app.currentAdmin;
    if (currentAdmin.id !== event.admin_id.toString()) {
      return;
    }

    if (event.status === BulkActionProgressionValues.Complete) {
      if (event.all_entities_unaffected) {
        this.knowledgeHubService.notifyNoEntitiesAffected(event.bulk_action);
      } else {
        this.sendCompletionNotification(event);
        taskFor(this.reloadList).perform();
      }
    }

    if (event.status === BulkActionProgressionValues.InvalidFolder) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.bulk-actions.invalid-folder'),
      );
    }
  }

  private async sendCompletionNotification(event: BulkActionProgressionEvent) {
    let action = event.bulk_action;
    let unaffectedEntityTypes = event.unaffected_entity_types;
    let language_code = event.language_code;
    let destinationFolderId = event.destination_folder_id;
    this.knowledgeHubService.notifyBulkActionCompleted(
      action,
      unaffectedEntityTypes,
      language_code,
      destinationFolderId,
    );
    if (destinationFolderId) {
      await this.knowledgeHubService.fetchFoldersOnce(true);
    }
  }

  get columnsToDisplay() {
    if (this.hasAppliedFilter) {
      return this.columnsWhenFiltering;
    }
    return this.columns;
  }

  get selectableColumns() {
    let columns = this.args.selectableColumns ?? DEFAULT_SELECTABLE_COLUMNS;
    if (!this.appService.app.canUseKnowledgeHubCollectionsColumn) {
      columns = columns.filter((column) => column.value !== 'collectionNames');
    }
    return columns;
  }

  hardReloadImportSources() {
    this.store.findAll('import-service/import-source', { reload: true });
  }

  get columns(): TableColumn[] {
    let columns: TableColumn[];
    if (this.columnsLocalStorage) {
      columns = JSON.parse(this.columnsLocalStorage) as TableColumn[];
    } else if (this.args.folder) {
      let hasMultilingual = this.args.listApi.contentWrappers.any(
        (contentWrapper: KnowledgeHubContentWrapper) => contentWrapper?.isMultilingual,
      );
      columns = hasMultilingual
        ? (FOLDER_MULTILINGUAL_DEFAULT_COLUMNS as TableColumn[])
        : this.defaultColumns;
    } else {
      columns = this.defaultColumns;
    }

    if (!this.appService.app.canUseKnowledgeHubCollectionsColumn) {
      columns = columns.filter((column) => column.valuePath !== 'collectionNames');
    }

    // We need to ensure that the HC sign post component is rendered to customers who have visited this page and have columns stored in local storage
    // Otherwise, we'd need them to remove & re-add the column to see the HC sign post component
    let statusColumn = columns.find((column) => column.valuePath === 'status');
    if (statusColumn && !statusColumn.customHeaderComponent) {
      statusColumn.customHeaderComponent =
        'knowledge-hub/filterable-list/table/custom-column-headers/help-center-status';
    }

    return columns;
  }

  set columns(columns: TableColumn[]) {
    this.columnsLocalStorage = JSON.stringify(columns);
  }

  get defaultColumns(): TableColumn[] {
    return KNOWLEDGE_HUB_ALL_CONTENT_DEFAULT_COLUMNS as TableColumn[];
  }

  get parentFolder() {
    return this.args.folder?.parentFolder;
  }

  get subFoldersCount() {
    return this.args.folder?.orderedSubFolders.length || 0;
  }

  get isNewSubFolderDisabled() {
    return this.args.folder?.atMaxDepth();
  }

  get showNewContentButton() {
    return this.args.folder ? this.args.folder.isEditable : true;
  }

  get showManageSourcesButton() {
    return this.args.folder?.isSynced;
  }

  get hasAppliedFilter(): boolean {
    return (
      !isEmpty(this.args.searchTerm) ||
      !isEmpty(this.args.selectedTypes) ||
      !isEmpty(this.args.filtersApplied)
    );
  }

  get showEmptyState() {
    return (
      !this.args.listApi.isLoadingPage &&
      this.args.listApi.totalCount === 0 &&
      !this.hasAppliedFilter
    );
  }

  get treeItem() {
    return this.args.folder
      ? this.args.folder.treeItem
      : this.knowledgeHubService.tree?.children.firstObject;
  }

  @action resetFilters() {
    this.args.resetFilters();
    this.columnsWhenFiltering = this.columns;
    this.args.listApi.reset();
    taskFor(this.reloadPage).perform();
  }

  @action beforeSearch() {
    if (!this.args.listApi.isFiltering) {
      // This is the first filter / search being applied
      this.ensureFolderColumnVisible();
    }
  }

  @action ensureFolderColumnVisible() {
    let otherColumns = this.columnsWhenFiltering.reject(
      (column) => column.valuePath === ALL_CONTENT_COLUMN_DATA.folder.valuePath,
    );
    this.columnsWhenFiltering = otherColumns.concat(ALL_CONTENT_COLUMN_DATA.folder);
  }

  @restartableTask *reloadList(): TaskGenerator<void> {
    yield this.args.listApi.search(this.args.searchTerm);
    yield this.args.listApi.refreshPageAfterDelay();
  }

  @restartableTask *reloadPage(): TaskGenerator<void> {
    yield this.args.listApi.loadPage();
  }

  get showAllTranslations() {
    return this.showAllToggled || this.args.listApi.requiresShowTranslations;
  }

  @action
  toggleTranslations() {
    this.showAllToggled = !this.showAllToggled;
    this.args.listApi.requiresShowTranslations = false;
    localStorage.setItem(SHOW_ALL_TRANSLATIONS, this.showAllToggled ? 'true' : 'false');
  }

  @action
  closeSourceModal() {
    this.step = undefined;
    this.knowledgeHubService.removeEmptySources();
  }

  @action
  async openCreateFolderModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    this.showCreateFolderModal = true;
  }

  @action
  setSyncStep(step: SyncSteps) {
    this.step = step;
  }

  @action
  updatedSelectedTableColumns(columns: string[]) {
    let selectedColumns = columns.map((col) => ALL_CONTENT_COLUMN_DATA[col]);
    this.columns = KNOWLEDGE_HUB_REQUIRED_COLUMNS.concat(selectedColumns);
    this.columnsWhenFiltering = this.columns;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'update_columns',
      object: 'all_content',
      place: 'knowledge_hub',
    });
  }

  @action toggleNewContentModal(state = false): void {
    this.showNewContentModal = state;
    this.trackAnalyticsEvent('clicked', 'new_content_button');
  }

  @action
  setSelectedFragments(fragments: Set<ContentFragment>) {
    this.selectedFragments = fragments;
  }

  @action
  setUnselectedFragments(fragments: Set<ContentFragment>) {
    this.unselectedFragments = fragments;
  }

  @action
  setIsBulkSelection(state: boolean) {
    this.isBulkSelection = state;
  }

  @action
  resetSelectedFragments() {
    this.selectedFragments = new Set();
    this.unselectedFragments = new Set();
    this.isBulkSelection = false;
  }

  @action onClickManageSources() {
    this.router.transitionTo('apps.app.knowledge-hub.overview');
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'knowledge_hub',
      context: 'all_content',
      place: 'all_content',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList': typeof FilterableList;
    'knowledge-hub/filterable-list': typeof FilterableList;
  }
}
